import React from "react";
import { Link } from "gatsby";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";


import Layout from "../../components/layout";
import Seo from "../../components/seo";
import './product.css'
//import TestUseMemo from "../../components/testUseMemo";
//import TestUseContext from "../../components/testUseContext";

export default function Product(pageData) {
  const product = pageData.pageContext.product;
  //const allProducts = pageData.pageContext.allProducts;
  //const [pageContent, setPageContent] = useState('')

/*   fetch(`https://sys.abacus.pl/del/api_json.php`)
  .then(response => response.json()) // parse JSON from request
  .then(resultData => {
    setPageContent( JSON.stringify(resultData, null, 4))
  }) // set data for the number of stars */

/*   const gabarytyBox = () => (
    <div class="card mb-3" style={{ maxWidth: "540px" }}>
      <div class="row g-0">
        <div class="col-md-4">
          <img
            src="/img/rys-wymiary.png"
            class="img-fluid rounded-start"
            alt="..."
          />
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">
              This is a wider card with supporting text below as a natural
              lead-in to additional content. This content is a little bit
              longer.
            </p>
            <p class="card-text">
              <small class="text-muted">Last updated 3 mins ago</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  ); */


  
  const [tenProduktImg] = product.productsImg.filter(p => {
    //console.log(p.webName)
    if (p.webName === `/img/akumulatory/${product.marka_path}_${product.nazwa_path.replace(/([a-z]*)-/,"$1")}.webp`) return p
    else return false
  })

  return (
    <Layout>
{/*        <pre>{JSON.stringify(tenProduktImg, null, 10)}</pre>  */}
    
     {/*  <TestUseMemo/> */}
{/*       <TestUseContext /> */}
      <Seo title={`Akumulator ${product.marka} ${product.produkt_nazwa}`} />     

      {/* <pre>{JSON.stringify(product, null, 4)}</pre> */}

      <section className="py-5 text-center container">
        <div className="row py-lg-5">
          <div className="col-lg-6 col-md-8 mx-auto">
            <h1 className="fw-light">{`${product.marka} ${product.produkt_nazwa.replace(/([A-Z]*)\s/,"$1") }`}</h1>
            <p className="lead text-muted">{`Akumulator AGM ${product.oferta_poj}Ah ${product.oferta_U}V`}</p>

{/*             <p>slug: {product.slug3}</p>
            <p>nazwa_pathModified= {product.nazwa_path.replace(/([a-z]*)-/,"$1")}</p> */}
       {/*  <p>SLUGG {product.marka_path+'/'+product.nazwa_path.replace(/([a-z]*)\-/,"$1")}</p> */}

  {/*           <h2>Akumulator AGM <b>12</b>V <b>40</b>Ah <sup>[C20]</sup></h2>   */}
      {/*       <p>Akumulator posiada biegun dodatni po prawej stronie: polaryzacja prawy plus (P+)</p> */}

{/*             <p>Opis akumulatora ...</p> */}
            
{/*             <p>Cena: 240 zł netto (202 zł brutto)</p>
            <p>Stan magazynowy: <div>zielone paski</div> Towar dostępny na magazynie, wysyłka 24h (w dni robocze pn-pt)</p>
            <p>Koszt wysyłki: <b>19</b> zł netto  <b>25</b> zł brutto <small>(paczka do 30 kg)</small></p> */}

{/*             <p className="text-muted">Seria <b>BPS</b> typ <b>BPS40-12</b> </p>
 */}
            <p>
{/*             <Img fixed={`static/img/akumulatory/akumulator-${product.marka_path}_${product.nazwa_path.replace(/([a-z]*)-/,"$1")}.webp`} alt={product.nazwa} />
 */}


{/*  {pageData.pageResources.json.data.photos.edges.map((img) => (
        <div key={img.node.id}>
          <GatsbyImage
            fluid={img.node.childImageSharp.fluid}
             alt={product.nazwa}
            image={getImage(img.node)}
          />
        </div>
      ))}
 */}

               { tenProduktImg && <img
                  srcset={`
                    ${tenProduktImg.webNamex2} 2x
                    ${tenProduktImg.webName} 1x
                  `}
                  src={tenProduktImg.webName}
                  //style={tenProduktImg.webStyle}
                  width={tenProduktImg.dimensions.width} 
                  height={tenProduktImg.dimensions.height}                   
                  alt={product.nazwa} 
                
                /> }

{/*             <img style={{height:"200px"}} src="/img/bps-100-12_koncowki.webp" alt="" /> 
            <img style={{height:"200px"}} src="/img/bps-100-12_koncowki_2.webp" alt="" />             
            <img style={{height:"200px"}} src="/img/bps-100-12_karta_katalogowa.png" alt="" />  */}
            </p>

{/*             <p>
              <button className="btn btn-primary">+ Dodaj do koszyka</button> 
              <input type="number"/> szt 
              <p><a href="#">dodaj do "schowka"</a></p>
            </p> */}

            <h2>Gabaryty</h2>
            <ul className="gabaryty">
              <li>Długość: <b>{product.oferta_dlug}</b> mm</li>   
              <li>Szereokość: <b>{product.oferta_szer}</b> mm</li>            
              <li>Wysokośc: <b>{product.oferta_wys}</b> mm</li>
              <li>Waga: ~<b>{product.oferta_waga}</b> kg</li>              
            </ul>

        {/*     <img style={{height:"200px"}} src="/img/bps-100-12_rys-wymiary.png" alt="" /> */}

          </div>
        </div>
        <div className="row">
          <Link to="/kontakt/" className="btn btn-primary my-2">kontakt</Link>
        </div>
      </section>
    </Layout>
  );
}


export const pageQuery = graphql`
  query  ($imgPrefix: String)  {
    photos: allFile(
      sort: { fields: base, order: ASC }
      filter: {name: {eq: $imgPrefix}}
      limit: 3

    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              quality: 70 # 50 by default
            )
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;